<template>
<v-container fluid>
  <v-flex>
    <v-card>
      <v-toolbar color="#0f2e8c" flat>
        <h3 class="white--text">Quản lý {{Filter.Mode ==='don-hang' ? 'đơn hàng':'kho hàng'}}</h3>
        <v-spacer />
        <v-btn
            class="mx-2"
            color="green"
            dark
            v-if="loginInfo._perm === 'admin'"
            :loading="downloadTrackingExcelReport"
            :disabled="downloadTrackingExcelReport"
            @click="doExportReport"
        >
          Báo cáo
        </v-btn>
        <v-menu
            v-if="(loginInfo._perm === 'admin' || loginInfo._perm === 'warehousevn' || loginInfo._perm === 'manager') && Filter.Mode === 'don-hang'"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mx-2"
                color="blue"
                dark
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-truck</v-icon> Tracking
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  :dark="!(selectedItems <= 0 || selectedItems.length !== countSelectedExportTrackingItemsValid || downloadTrackingExcel)"
                  color="blue darken-2"
                  :disabled="selectedItems <= 0 || selectedItems.length !== countSelectedExportTrackingItemsValid || downloadTrackingExcel"
                  :loading="downloadTrackingExcel"
                  @click="doExportTrackingItems"
                >
                  <v-icon>
                    mdi-table-arrow-down
                  </v-icon>
                  Xuất File Excel
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                    dark
                    color="blue darken-4"
                    @click="doImportTracking()"
                >
                  <v-icon>
                    mdi-table-arrow-up
                  </v-icon>
                  Nhập File Excel
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
            class="mx-2"
            color="red"
            dark
            @click="doDeleteItems"
            v-if="(loginInfo._perm === 'admin' || loginInfo._perm === 'warehousevn') && Filter.Mode === 'khoi-tao'"
            :disabled="selectedItems.length <= 0 || countSelectedDeleteItemsInvalid > 0"
        >
          <v-icon>mdi-store-remove</v-icon> Xóa sản phẩm
        </v-btn>
        <v-btn
            class="mx-2"
            color="red"
            dark
            @click="doRestockItems"
            v-if="(loginInfo._perm === 'admin') && Filter.Mode === 'ton-kho'"
            :disabled="selectedItems.length <= 0 || countSelectedDeleteItemsInvalid > 0"
        >
          <v-icon>mdi-store-remove</v-icon> Xóa sản phẩm
        </v-btn>
        <v-btn
            class="mx-2"
            color="green"
            dark
            @click="doXuatKho"
            :disabled="selectedItems.length <= 0 || countSelectedItemsInvalid > 0"
            v-if="Filter.Mode === 'ton-kho' && loginInfo._perm !== 'warehousevn'"
        >
          <v-icon>mdi-home-export-outline</v-icon> Xuất Kho
        </v-btn>
<!--        <v-btn-->
<!--            class="mx-2"-->
<!--            color="blue dark-3"-->
<!--            dark-->
<!--            @click="doConfirmExportItems"-->
<!--            v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'warehousevn'"-->
<!--            :disabled="selectedItems.length <= 0 || countSelectedConfirmItemsInvalid > 0"-->
<!--        >-->
<!--          <v-icon>mdi-checkbox-multiple-marked-outline</v-icon> Xác nhận xuất-->
<!--        </v-btn>-->
<!--        <v-btn-->
<!--            class="mx-2"-->
<!--            color="orange lighten-2"-->
<!--            dark-->
<!--            @click="doConfirmPaymentItems"-->
<!--            v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"-->
<!--            :disabled="selectedItems.length <= 0 || countSelectedConfirmPaymentInvalid > 0"-->
<!--        >-->
<!--          <v-icon>mdi-cash-sync</v-icon> Xác nhận thanh toán-->
<!--        </v-btn>-->
        <v-menu
            offset-y
            v-if="(loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'warehousevn') && Filter.Mode === 'khoi-tao'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mx-2"
                color="green"
                dark
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-home-import-outline</v-icon> Nhập Kho
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                    color="green"
                    dark
                    @click="boxAddBatch = true"
                    v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'warehousevn'"
                    small
                >
                  Tạo nhanh mã kho
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  dark
                  color="blue"
                  @click="boxAdd = true"
                  small
                  v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'warehousevn'"
                >
                  Nhập 1 sản phẩm
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                    dark
                    color="blue"
                    @click="doNhapKhoExcel"
                    small
                    v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'warehousevn'"
                >
                  Nhập từ File Excel
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
            class="mx-2"
            color="green"
            dark
            @click="doXuatExcel"
            v-if="(loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'warehousevn') && Filter.Mode === 'khoi-tao'"
        >
          <v-icon>mdi-microsoft-excel</v-icon>
          Xuất Excel mã Kho {{selectedItems.length > 0 ? selectedItems.length : totalItems }} sản phẩm
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-row>
          <v-col
            cols="4"
          >
              <v-row>
                <v-col
                    cols="auto"
                >
                  <v-chip
                      dark
                      @click="doSearchNH(false)"
                      color="pink"
                  >
                    Tất cả
                  </v-chip>
                </v-col>
                <v-col
                  cols="auto"
                  v-for="item in NH"
                  :key="item._id"
                >
                  <v-chip
                    dark
                    @click="doSearchNH(item)"
                    :color="item._id === Filter.NH ? 'green':'blue'"
                  >
                    {{item.Ten}}
                  </v-chip>
                </v-col>
              </v-row>
          </v-col>
          <v-col
            cols="2"
          >
            <v-card>
              <v-card-title>THỐNG KÊ</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    Lợi nhuận Sale C1: <strong>{{DoiTien(Stats.SumC1)}}</strong>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    Hoa Hồng C2: <strong>{{DoiTien(Stats.SumC2)}}</strong>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="6"
          >
            <v-row
            >
              <template
                  v-if="Filter.Mode === 'don-hang'"
              >
                <v-col
                  cols="auto"
                >
                  <v-select
                      v-model="Filter.TrangThai"
                      :items="[{text:'Tất cả', value:null}, {text:'Xin xuất', value:-2}, {text:'Đã xuất',value:0}, {text:'Xác nhận xuất',value:-3}, {text:'Hoàn thành',value:-4}]"
                      label="Trạng thái kho hàng"
                  />
                </v-col>
                <v-col
                    cols="auto"
                >
                  <v-select
                      v-model="Filter.ThanhToan"
                      :items="[{text:'Tất cả', value:null}, {text:'COD', value:'COD'}, {text:'Chuyển khoản',value:'CK'}]"
                      label="Hình thức thanh toán"
                  />
                </v-col>
                <v-col
                    cols="auto"
                >
                  <v-select
                      v-model="Filter.TTThanhToan"
                      :items="[{text:'Tất cả', value:null}, {text:'Bill tạm', value:'bill_tam'}, {text:'Chờ thanh toán',value:'cho_tt'}, {text:'Đã thanh toán',value:'da_tt'}, {text:'Khóa thanh toán',value:'khoa_tt'}, {text:'Lỗi thanh toán',value:'loi_tt'}]"
                      label="Trạng thái thanh toán"
                  />
                </v-col>
                <v-col
                    cols="auto"
                >
                  <v-select
                      v-model="Filter.Sale"
                      :items="[{Username:'Tất cả', _id:null}].concat(Sales)"
                      item-value="_id"
                      item-text="Username"
                      label="Sale"
                  />
                </v-col>
              </template>
              <v-col
                  cols="auto"
                  v-if="Filter.Mode === 'ton-kho' || Filter.Mode === 'don-hang'"
              >
                <v-menu
                    ref="menu_created_at"
                    v-model="menu_created_at"
                    :close-on-content-click="false"
                    :return-value.sync="Filter.byDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="ByDate"
                        label="Lọc thời gian"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                  </template>
                  <v-date-picker
                      v-model="FilterTime"
                      no-title
                      scrollable
                      range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="doCancelFilterByDate"
                    >
                      Hủy
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="doSaveFilterByDate"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="6"
              >
                <v-text-field
                    v-model="Filter.Search"
                    label="Mã Kho"
                    class="block"
                    clearable
                    persistent-placeholder
                    @click:clear="doClearSearch"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  :dark="!loading"
                  :disabled="loading"
                  :loading="loading"
                  color="blue"
                  @click="doDownload()"
                  class="mx-auto"
                >
                  Tìm kiếm
                  <v-icon right>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
            v-model="selectedItems"
            :loading="loading"
            :headers="headers"
            :items="items"
            :search="Search"
            loading-text="Đang tải dữ liệu..."
            :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50,100]}"
            :items-per-page="100"
            max-height="750px"
            fixed-header
            :options.sync="Options"
            :server-items-length="totalItems"
            show-select
            selectable-key="_id"
            item-key="_id"
        >
          <template v-slot:[`body.append`]>
            <HeaderBody
                :Mode="Filter.Mode"
                :Manager="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
                :GiaNhap="sumGiaNhap"
                :GiaNY="sumGiaNY"
                :LNTT="sumLNTT"
                :CKTT="sumCKTT"
                :LNS1="sumLNS1"
                :CPK="sumCPK"
                :KDC="sumKDC"
                :LNS2="sumLNS2"
                :LNG="sumLNG"
                :LNR="sumLNR"
                :Ship="sumShip"
                :TLN="sumTLN"
                :DTK="sumDTK"
                v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'sale' || loginInfo._perm === 'accounting'"
            />
          </template>
          <template v-slot:[`body.prepend`]>
            <HeaderBody
                :Mode="Filter.Mode"
                :Manager="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
                :GiaNhap="sumGiaNhap"
                :GiaNY="sumGiaNY"
                :LNTT="sumLNTT"
                :CKTT="sumCKTT"
                :LNS1="sumLNS1"
                :CPK="sumCPK"
                :KDC="sumKDC"
                :LNS2="sumLNS2"
                :LNG="sumLNG"
                :LNR="sumLNR"
                :Ship="sumShip"
                :TLN="sumTLN"
                :DTK="sumDTK"
                v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'sale' || loginInfo._perm === 'accounting'"
            />
          </template>
          <template v-slot:[`item.MaKho`]="{item}">
            {{item.MaKho}}
            <v-btn
                dark
                color="blue"
                small
                @click="doToggleEdit(item)"
                icon
                class="mx-2"
                v-if="(item.Ton === -1 || item.Ton === 1) && loginInfo._perm.toString() === 'admin'"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.Tracking`]="{item}">
            <v-btn
                dark
                :color="item.Delivered ? 'green':'blue'"
                small
                v-if="item.Tracking"
                @click="doViewTracking(item)"
            >
              {{item.Tracking}}
            </v-btn>
          </template>
          <template v-slot:[`item.ThanhToan`]="{item}">
            <v-btn
              dark
              :color="item.ThanhToan === 'CK' ? 'green':'orange'"
              small
              v-if="item.ThanhToan"
            >
              {{item.ThanhToan === 'CK' ? 'Chuyển khoản':'COD'}}
            </v-btn>
          </template>
          <template v-slot:[`item.Sale`]="{item}">
            <template v-if="item.Sale">
              {{item.Sale.Username}} ({{item.Sale.TopSale ? item.Sale.TopSale.Username:'C1'}})
            </template>
          </template>
          <template v-slot:[`item.HD`]="{item}">
            <v-menu offset-y v-if="item.HoaDon">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  Hóa đơn
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      :dark="downloadInvoiceExcel === null"
                      color="green"
                      @click="doExportInvoice(item)"
                      :disabled="downloadInvoiceExcel === item._id"
                      :loading="downloadInvoiceExcel === item._id"
                    >
                      Xuất HĐ Excel
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="(item.HoaDon.TrangThaiThanhToan === 'cho_tt' || item.HoaDon.TrangThaiThanhToan === 'da_tt') && item.HoaDon.ThanhToan==='CK'">
                  <v-list-item-title>
                    <v-btn
                        :dark="uploadStateHD === null"
                        color="green"
                        @click="doStartUploadHD(item)"
                        :disabled="uploadStateHD === item._id"
                        :loading="uploadStateHD === item._id"
                    >
                      Đã thanh toán
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="(item.HoaDon.Image) && item.HoaDon.ThanhToan==='CK'">
                  <v-list-item-title>
                    <v-btn
                        dark
                        color="green"
                        @click="doViewImage(item)"
                    >
                      Xem hình ảnh tt
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.TrangThaiThanhToan`]="{item}">
            <v-btn dark v-if="item.TrangThaiThanhToan === 'loi_tt'" color="red" small>
              Lỗi thanh toán
            </v-btn>
            <v-btn dark v-if="item.TrangThaiThanhToan === 'cho_tt'" color="orange" small>
              Chờ thanh toán
            </v-btn>
            <v-btn dark v-if="item.TrangThaiThanhToan === 'da_tt'" color="blue" small>
              Đã thanh toán - Chờ xác nhận
            </v-btn>
            <v-btn dark v-if="item.TrangThaiThanhToan === 'khoa_tt'" color="green">
              Khóa thanh toán - Đã xác nhận
            </v-btn>
          </template>
          <template v-slot:[`item.ChietKhauTT`]="{item}">
            {{DoiTien(item.CKTT)}}
          </template>
          <template v-slot:[`item.LoiNhuanTT`]="{item}">
            <strong><span class="text-subtitle-1 font-weight-bold">{{DoiTien(item.LNTT)}}</span></strong>
          </template>
          <template v-slot:[`item.LNR`]="{item}">
            {{DoiTien(item.LNR)}}
          </template>
          <template v-slot:[`item.DTK`]="{item}">
            {{DoiTien(item.DTK)}}
          </template>
          <template v-slot:[`item.CPK`]="{item}">
            {{DoiTien(item.CPK)}}
          </template>
          <template v-slot:[`item.PhiShipNoiDia`]="{item}">
            {{DoiTien(item.PhiShipNoiDia)}}
          </template>
          <template v-slot:[`item.LoiNhuanGop`]="{item}">
            <strong><span class="text-subtitle-1 font-weight-bold">{{DoiTien(item.LoiNhuanGop)}}</span></strong>
          </template>
          <template v-slot:[`item.TongLoiNhuan`]="{item}">
            <strong><span class="text-subtitle-1 font-weight-bold">{{DoiTien(item.TongLoiNhuan)}}</span></strong>
          </template>
          <template v-slot:[`item.LoiNhuanS2`]="{item}">
            <strong><span class="text-subtitle-1 font-weight-bold">{{DoiTien(item.LoiNhuanS2)}}</span></strong>
          </template>
          <template v-slot:[`item.LoiNhuanS1`]="{item}">
            <template v-if="item.Ton === 1">
              <strong><span class="text-subtitle-1 font-weight-bold">{{TinhLN(item)}}</span></strong>
            </template>
            <template v-else>
              <strong><span class="text-subtitle-1 font-weight-bold">{{DoiTien(item.LoiNhuanS1)}}</span></strong>
            </template>
          </template>
          <template v-slot:[`item.KDC`]="{item}">
            {{DoiTien(item.KDC)}}
          </template>
          <template v-slot:[`item.CKS2`]="{item}">
            {{item.CKS2}}%
          </template>
          <template v-slot:[`item.CKS1`]="{item}">
            <template v-if="item.Ton === 1">
              {{TinhPT(item)}}%
            </template>
            <template v-else>
              {{item.CKS1}}%
            </template>
          </template>
          <template v-slot:[`item.TenNguoiMua`]="{item}">
            <template v-if="item.TenNguoiMua">
                <v-btn
                    dark
                    color="blue"
                    class="mx-auto"
                    @click="doToggleKhachHang(item)"
                    small
                    block
                >
                  {{truncate(item.TenNguoiMua,10)}}
                </v-btn>
            </template>
          </template>
          <template v-slot:[`item.Barcode`]="{item}">
            <v-btn
                :dark="downloadBarcodeState === null"
                color="blue"
                small
                :disabled="downloadBarcodeState === item._id"
                :loading="downloadBarcodeState === item._id"
                @click="doExportBarCode(item)"
                class="ma-1"
            >
              Tải Excel Barcode
            </v-btn>
            <v-btn
                :dark="getPDFState === null"
                :color="item.In >= 2 ? 'green':'blue'"
                small
                :disabled="getPDFState === item._id"
                :loading="getPDFState === item._id"
                @click="doGetPDF(item)"
                class="ma-1"
            >
              <v-icon>
                mdi-printer
              </v-icon>
               In
            </v-btn>
            <v-btn
                :dark="getPDFState === null"
                :color="item.In3x4 >= 1 ? 'green':'blue'"
                small
                :disabled="getPDFState === item._id"
                :loading="getPDFState === item._id"
                @click="doGetPDF(item, true)"
                class="ma-1"
            >
              <v-icon>
                mdi-printer
              </v-icon>
              In*
            </v-btn>
          </template>
          <template v-slot:[`item.NganhHang`]="{item}">
            {{item.NH.Ten}} ({{item.NH.Ma}})
          </template>
          <template v-slot:[`item.Ton`]="{item}">
            <template v-if="item.Ton === -4">
              <v-btn
                  dark
                  color="green"
              >
                Hoàn thành
              </v-btn>
              <template v-if="item.HoaDon">
                <span
                    class="mt-2 mx-auto">
                  {{parseDateTime(item.HoaDon.created_at)}}
                </span>
              </template>
            </template>
            <template v-if="item.Ton === -3">
              <v-btn
                  dark
                  color="orange"
              >
                Xác nhận xuất
              </v-btn>
              <template v-if="item.HoaDon">
                <div
                    class="mt-2 mx-auto">
                  {{parseDateTime(item.HoaDon.created_at)}}
                </div>
              </template>
            </template>
            <template v-if="item.Ton === 0">
              <v-btn
                dark
                color="red"
              >
                Đã xuất
              </v-btn>
              <template v-if="item.HoaDon">
                <div
                  class="mt-2 mx-auto">
                  {{parseDateTime(item.HoaDon.created_at)}}
                </div>
              </template>
            </template>
            <template v-else-if="item.Ton === -1">
              <v-btn
                dark
                color="orange"
              >
                KHỞI TẠO
              </v-btn>
            </template>
            <template v-else-if="item.Ton === -2">
              <v-btn
                dark
                color="blue"
              >
                Xin xuất
              </v-btn>
              <template v-if="item.HoaDon">
                <div
                    class="mt-2 mx-auto">
                  {{parseDateTime(item.HoaDon.created_at)}}
                </div>
              </template>
            </template>
            <template v-if="item.Ton === 1">
              <v-btn
                  dark
                  color="green"
                  @click="doXinXuatKho(item)"
              >
                TỒN KHO
              </v-btn>
            </template>
          </template>
          <template v-slot:[`item.GiaNhap`]="{item}">
            {{DoiTien(item.GiaNhap)}}
          </template>
          <template v-slot:[`item.GiaNY`]="{item}">
            <strong><span class="text-subtitle-1 font-weight-bold">{{DoiTien(item.GiaNY)}}</span></strong>
          </template>
          <template v-slot:[`item.Images`]="{item}">
            <template v-if="item.Images && item.Images.length > 0">
              <v-img
                :src="`${statics}/${item.Images[0]}`"
                max-width="75px"
                max-height="75px"
                class="mb-2 mx-auto justify-center align-center"
              />
            </template>
            <v-btn
                dark
                color="blue"
                small
                @click="doToggleImage(item)"
                icon
                class="mx-auto"
            >
              <v-icon>
                mdi-camera-burst
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.GhiChu`]="{item}">
            <v-btn
              dark
              color="blue"
              small
              @click="doToggleNote(item)"
            >
              Có {{item.GhiChu.length}} Ghi chú
            </v-btn>
          </template>
          <template v-slot:[`item.ChietKhau`]="{item}">
            <p>
              <strong>
                {{item.CK === 0 ? 'A':'B'}} ({{item.CK === 0 ? item.CKA:item.CKB}}%)
              </strong>
            </p>
            <p>
              <template v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'">
                <strong>A:</strong> {{item.CKA}}% <strong>|</strong> <strong>B:</strong> {{item.CKB}}%
              </template>
            </p>
            <v-btn
              dark
              small
              color="blue"
              class="mt-2"
              v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
              @click="doToggleCK(item)"
              icon
            >
              <v-icon>
                mdi-clipboard-edit-outline
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.Action`]="{item}">
            <v-flex>
              <v-btn
                  icon
                  color="blue"
                  @click="doToggleEdit(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  icon
                  color="red"
                  @click="doDelete(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-flex>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-flex>
  <v-dialog
    v-model="boxAdd"
    persistent
    max-width="800px"
  >
    <v-card flat>
      <v-card-title>
        Nhập Kho
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="updateData.MaSP"
              label="Mã SP"
              class="mx-auto"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="updateData.ThuongHieu"
                label="Thương hiệu"
                class="mx-auto"
            />
          </v-col>
          <v-col cols="4">
            <v-select
                v-model="updateData.NH"
                :items="NH"
                item-value="_id"
                item-text="Ten"
                label="Ngành hàng"
                class="mx-auto"
            />
          </v-col>
          <v-col cols="4">
            <v-select
                v-model="updateData.TinhTrang"
                :items="['100%','95%','90%','85%','80%']"
                label="Tình trạng"
                class="mx-auto"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="updateData.NamSX"
                label="Năm SX"
                class="mx-auto"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="updateData.KL"
                label="Khối lượng"
                class="mx-auto"
            />
          </v-col>
          <v-col cols="6">
            <v-currency-field
                v-model="updateData.GiaNhap"
                label="Giá nhập"
                class="mx-auto"
                v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
            />
          </v-col>
          <v-col cols="6">
            <v-currency-field
                v-model="updateData.GiaNY"
                label="Giá niêm yết"
                class="mx-auto"
                v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="updateData.CKA"
                label="Chiết khấu A"
                class="mx-auto"
                v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="updateData.CKB"
                label="Chiết khấu B"
                class="mx-auto"
                v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
            />
          </v-col>
          <v-col cols="4">
            <v-select
                v-model="updateData.CK"
                :items="[{value:0,text:'A'},{value:1,text:'B'}]"
                label="Phương án chiết khấu"
                class="mx-auto"
                v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
            />
          </v-col>
          <v-col cols="4">
            <v-textarea
                v-model="updateData.ThongSo"
                label="Thông số"
                class="mx-auto"
            />
          </v-col>
          <v-col cols="8">
            <v-textarea
                v-model="updateData.MoTa"
                label="Mô tả"
                class="mx-auto"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :dark="!createState"
          color="green"
          :loading="createState"
          :disabled="createState"
          @click="doCreate"
          v-if="updateData.ID === null"
        >
          Nhập kho
        </v-btn>
        <v-btn
            :dark="!createState"
            color="green"
            :loading="createState"
            :disabled="createState"
            @click="doUpdate"
            v-if="updateData.ID !== null"
        >
          Lưu
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          @click="doCloseBoxAdd"
        >
          Đóng
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="boxNote"
    persistent
    max-width="800px"
  >
    <v-card flat>
      <v-card-title>Ghi chú sản phẩm</v-card-title>
      <v-card-text>
        <span v-if="Notes.length === 0">
          Chưa có ghi chú
        </span>
        <v-list>
          <template v-for="(item, index) in Notes">
            <v-list-item
                :key="item"
            >
              <v-list-item-content>
                <v-list-item-title v-html="item"></v-list-item-title>
                <v-list-item-subtitle>
                  <v-spacer />
                  <v-btn
                      icon
                      color="deep-orange"
                      @click="doConfirmDeleteNote(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
                :key="index"
            />
          </template>
        </v-list>
      </v-card-text>
      <v-card-text>
        <Loader :status="noteState" />
        <v-row>
          <v-col cols="8">
            <v-textarea
              v-model="Note"
              label="Ghi chú"
              class="mx-2"
            />
          </v-col>
          <v-col cols="4">
            <v-btn
              color="blue"
              :dark="!noteState"
              :disabled="noteState"
              :loading="noteState"
              @click="doUpdateNote"
            >
              Thêm ghi chú
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          dark
          @click="boxNote = false"
        >
          Đóng
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="boxImage"
    persistent
    max-width="900px"
  >
    <v-card flat>
      <v-card-title>Quản lý hình ảnh</v-card-title>
      <v-card-text>
        <template v-if="Images.length > 0">
          <v-item-group
              v-model="selectedImg"
              multiple
              v-if="Images.length > 0"
          >
            <v-row>
              <v-col
                  v-for="(item, i) in Images"
                  :key="i"
                  cols="12"
                  md="6"
              >
                <v-item v-slot="{ active, toggle }">
                  <div>
                  <v-img
                      :src="`${statics}/${item.src}`"
                      height="150"
                      class="text-right pa-2"
                      @click="toggle"
                  >
                    <v-btn
                        :icon="!active"
                        dark
                        color="red"
                        class="mx-2"
                    >
                      <v-icon>
                        {{ active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank' }}
                      </v-icon>
                      {{active ? 'Đã chọn':''}}
                    </v-btn>
                  </v-img>
                    <v-btn
                        dark
                        color="blue"
                        class="ma-2"
                        small
                        @click="openGallery(i)"
                    >
                      <v-icon>
                        mdi-eye
                      </v-icon>
                      Phóng to
                    </v-btn>
                  </div>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
          <v-btn
            color="red"
            :dark="selectedImg.length > 0 || !removeImageState"
            class="ma-2"
            :disabled="selectedImg.length === 0 || removeImageState"
            :loading="removeImageState"
            @click="doConfirmRemoveImage"
            v-if="allowDelete"
          >
            <v-icon>mdi-delete</v-icon>
            Xóa {{selectedImg.length}} hình
          </v-btn>
        </template>
        <template v-else>
          <span class="red--text">Chưa có hình ảnh</span>
        </template>
        <v-file-input
            ref="uploader"
            v-model="UploadImages"
            accept="image/*"
            truncate-length="15"
            label="Tải lên hình ảnh"
            class="hide"
            :loading="uploadState"
            @change="doUploadFiles"
            multiple
        />
        <Loader :status="uploadState" />
        <v-list v-if="UploadImages.length > 0">
          <v-list-item
              v-for="(file,index) in UploadImages"
              :key="file.name"
          >
            <v-list-item-title>
              {{file.name}}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{UploadImageState[index] ? UploadImageState[index]:'...'}}
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :dark="Images.length < 5 || !uploadState"
          color="blue"
          :disabled="Images.length >= 5 || uploadState"
          @click="doStartUpload"
          :loading="uploadState"
        >
          <v-icon left>
            mdi-upload
          </v-icon>
          Tải hình lên
        </v-btn>
        <v-btn
          :dark="!downloadImageState"
          color="blue"
          :disabled="downloadImageState"
          :loading="downloadImageState"
          @click="doDownloadALlImages"
        >
          <v-icon left>
            mdi-download
          </v-icon>
          Tải tất cả hình
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          @click="doCloseUpload"
        >
          Đóng
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="boxAddBatch"
    persistent
    max-width="600px"
  >
    <v-card flat>
      <v-card-title>Tạo nhanh mã kho</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="createBatch.SL"
              class="mx-2"
              label="Số lượng"
            />
          </v-col>
          <v-col
              cols="6"
          >
            <v-select
                v-model="createBatch.NH"
                :items="NH"
                item-value="_id"
                item-text="Ten"
                label="Ngành hàng"
                class="mx-auto"
            />
          </v-col>
          <template v-if="loginInfo._perm === 'admin'|| loginInfo._perm === 'manager'">
            <v-col cols="4">
              <v-select
                  v-model="createBatch.CK"
                  :items="[{value:0,text:'A'},{value:1,text:'B'}]"
                  label="Phương án chiết khấu"
                  class="mx-auto"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                  v-model="createBatch.CKA"
                  label="Chiết khấu % A"
                  class="mx-auto"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                  v-model="createBatch.CKB"
                  label="Chiết khấu % B"
                  class="mx-auto"
              />
            </v-col>
          </template>
        </v-row>
        <Loader :status="createState" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          :dark="!createState"
          color="green"
          @click="doCreateBatch"
          :disabled="createState"
          :loading="createState"
        >
          Tạo mã kho
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          @click="boxAddBatch = false"
        >
          Đóng
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="boxCK"
    persistent
    max-width="500px"
  >
    <v-card flat>
      <v-card-title>
        Chỉnh sửa % CK
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="4"
          >
            <v-select
              v-model="updateCK.CK"
              :items="[{text:'A',value:0},{text:'B',value:1}]"
              label="Phương án CK"
            />
          </v-col>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="updateCK.CKA"
              label="Chiết khấu % A"
            />
          </v-col>
          <v-col
              cols="4"
          >
            <v-text-field
                v-model="updateCK.CKB"
                label="Chiết khấu % B"
            />
          </v-col>
        </v-row>
        <Loader :status="updateCKState" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          :dark="!updateCKState"
          :disabled="updateCKState"
          :loading="updateCKState"
          color="green"
          @click="doUpdateCK"
        >
          Lưu
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          @click="boxCK = false"
        >
          Đóng
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="boxKhachHang"
    persistent
    max-width="600px"
  >
    <v-card flat>
      <v-card-title>Thông tin khách hàng</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <div class="text-h6 ma-auto">
                  THÔNG TIN NGƯỜI MUA
                </div>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  :value="KhachHangData.TenNguoiMua"
                  label="Tên người mua"
                  readonly
                />
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    :value="KhachHangData.SDTNguoiMua"
                    label="SĐT người mua"
                    readonly
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
              cols="6"
          >
            <v-row>
              <v-col
                  cols="12"
              >
                <div class="text-h6 ma-auto">
                  THÔNG TIN NGƯỜI NHẬN
                </div>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    :value="KhachHangData.TenNguoiNhan"
                    label="Tên người nhận"
                    readonly
                />
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    :value="KhachHangData.SDTNguoiNhan"
                    label="SĐT người nhận"
                    readonly
                />
              </v-col>
              <v-col
                  cols="12"
              >
                <v-textarea
                    :value="KhachHangData.DCNguoiNhan"
                    label="Địa chỉ người nhận"
                    readonly
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          dark
          @click="boxKhachHang = false"
        >
          Đóng
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <LightBox
      ref="lightbox2"
      :media="Media"
      :show-light-box="false"
  />
  <LightBox
      ref="lightbox"
      :media="ImagesFull"
      :show-light-box="false"
  />
  <XuatKho
    ref="xuatkho"
    @Notice="notice"
    @doDownload="doDownload"
  />
  <NhapKho
    ref="nhapkho"
    @doDownload="doDownload"
    @Notice="notice.error"
  />
  <v-file-input
      ref="uploaderHD"
      v-model="HDImage"
      accept="image/*"
      class="hide"
      @change="doUploadHD"
  />
  <NhapTracking
    ref="nhaptracking"
    @update="doUpdateTracking"
  />
  <CheckTracking
    ref="checktracking"
  />
</v-container>
</template>

<script>
import {mapGetters} from "vuex";
import Query from '@/plugins/query/kho-hang.js';
import QueryHD from '@/plugins/query/kho-hoa-don.js';
import {Status} from "@/plugins/helper/dstatus";
import {initials, DoiTien, truncate} from "@/plugins/helper/string";
import Loader from '@/components/Loader';
import XuatKho from '@/components/kho/XuatKho.vue';
import NhapKho from '@/components/kho/NhapKho.vue';
import NhapTracking from '@/components/kho/Nhap-Tracking.vue';
import LightBox from 'vue-it-bigger'
import Global from "@/global";
import {parseDateTime} from '@/plugins/helper/timezone';
import('vue-it-bigger/dist/vue-it-bigger.min.css') // when using webpack
import HeaderBody from "@/components/kho/Kho-HeaderList-KhoHang.vue";
import HeaderDefault from '@/plugins/headers/default.json';
import HeaderKhoNganhHang from '@/plugins/headers/kho-nganh-hang.json';
import HeaderKhoNganhHangAdmin from '@/plugins/headers/kho-nganh-hang-admin.json';
import HeaderKhoKhoHang from "@/plugins/headers/kho-kho-hang.json";
import HeaderKhoKhoHangAdmin from "@/plugins/headers/kho-kho-hang-admin.json";
import HeaderKhoDonHangAdmin from "@/plugins/headers/kho-don-hang-admin.json";
import HeaderKhoDonHang from "@/plugins/headers/kho-don-hang.json";
import HeaderKhoDonHangKeToan from "@/plugins/headers/kho-don-hang-ke-toan.json";
import printJS from "print-js";
import CheckTracking from '@/components/kho/Kho-Xem-Tracking.vue';
export default {
  name: "Kho-Hang",
  components:{
    HeaderBody,
    Loader,
    LightBox,
    XuatKho,
    NhapKho,
    NhapTracking,
    CheckTracking,
  },
  computed:{
    ...mapGetters(['authStatus', 'isAuthenticated', 'loginInfo']),
    dataheader(){
      this.Filter.Mode = this.$route.params.Mode;
      this.Filter.NH = this.$route.params.NH;
      if(this.Filter.Mode === 'khoi-tao'){
        return (this.loginInfo._perm === 'admin') ? HeaderKhoNganhHangAdmin:HeaderKhoNganhHang;
      }
      if(this.Filter.Mode === 'ton-kho'){
        if(this.loginInfo._perm === 'warehousevn'){
          return this.headersKhoHangKho;
        }else {
          return (this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager') ? HeaderKhoKhoHangAdmin : HeaderKhoKhoHang;
        }
      }
      if(this.Filter.Mode === 'don-hang'){
        console.log(this.loginInfo._perm)
        if(this.loginInfo._perm === 'accounting'){
          return HeaderKhoDonHangKeToan;
        }
        if(this.loginInfo._perm === 'warehousevn'){
          return this.headersDonHangKho;
        } else {
          return (this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager') ? HeaderKhoDonHangAdmin : HeaderKhoDonHang;
        }
      }
    },
    countSelectedConfirmPaymentInvalid(){
      return this.selectedItems.filter(item=>item.TrangThaiThanhToan !== 'cho_tt' && item.TrangThaiThanhToan !== 'da_tt').length;
    },
    ByDate() {
      const data = this.FilterTime;
      if(data.length > 0) {
        return this.FilterTime.join(' ~ ')
      }
      return 'Lựa chọn thời gian'
    },
    sumDTK(){
      return this.items.reduce((partialSum, a) => partialSum + a.DTK, 0);
    },
    sumLNR(){
      return this.items.reduce((partialSum, a) => partialSum + a.LNR, 0);
    },
    sumShip(){
      return this.items.reduce((partialSum, a) => partialSum + a.PhiShipNoiDia, 0);
    },
    sumLNG(){
      return this.items.reduce((partialSum, a) => partialSum + a.LoiNhuanGop, 0);
    },
    sumTLN(){
      return this.items.reduce((partialSum, a) => partialSum + a.TongLoiNhuan, 0);
    },
    sumKDC(){
      return this.items.reduce((partialSum, a) => partialSum + a.KDC, 0);
    },
    sumCPK(){
      return this.items.reduce((partialSum, a) => partialSum + a.CPK, 0);
    },
    sumLNS1(){
      return this.items.reduce((partialSum, a) => partialSum + this.TinhLN(a,true), 0);
    },
    sumLNS2(){
      return this.items.reduce((partialSum, a) => partialSum + a.LoiNhuanS2, 0);
    },
    sumLNTT(){
      return this.items.reduce((partialSum, a) => partialSum + a.LNTT, 0);
    },
    sumCKTT(){
      return this.items.reduce((partialSum, a) => partialSum + a.CKTT, 0);
    },
    sumGiaNhap(){
      return this.items.reduce((partialSum, a) => partialSum + a.GiaNhap, 0);
    },
    sumGiaNY(){
      return this.items.reduce((partialSum, a) => partialSum + a.GiaNY, 0);
    },
    SelectedExportItemsTracking(){
      return this.selectedItems.filter(item=>item.Ton === -3 && ((item.TrangThaiThanhToan === 'khoa_tt' && item.ThanhToan === 'CK') || (item.ThanhToan === 'COD' && (item.TrangThaiThanhToan === 'cho_tt'))));
    },
    SelectedConfirmPaymentItems(){
      return this.selectedItems.filter(item=>item.TrangThaiThanhToan === 'cho_tt' || item.TrangThaiThanhToan === 'da_tt');
    },
    SelectedExportItems(){
      return this.selectedItems.filter(item=>item.Ton === -2);
    },
    countSelectedConfirmItemsInvalid(){
      return this.selectedItems.filter(item=>item.Ton !== -2).length;
    },
    SelectedDeleteItems(){
      return this.selectedItems.filter(item=>item.Ton === -1);
    },
    countSelectedExportTrackingItemsValid(){
      return this.selectedItems.filter(item=>item.Ton === -3 && ((item.TrangThaiThanhToan === 'khoa_tt' && item.ThanhToan === 'CK') || (item.ThanhToan === 'COD' && (item.TrangThaiThanhToan === 'cho_tt')))).length;
    },
    countSelectedDeleteItemsInvalid(){
      return this.selectedItems.filter(item=>item.Ton !== -1).length;
    },
    countSelectedItemsInvalid(){
      return this.selectedItems.filter(item=>item.Ton !== 1).length;
    },
    staticsHD(){
      return `${Global.staticTOP}/kho-hoa-don`;
    },
    statics(){
      return `${Global.staticTOP}/kho`;
    },
    ImagesFull(){
      return this.Images.map(img=>({
        ...img,
        src:`${this.statics}/${img.src}`,
        thumb:`${this.statics}/${img.src}`,
      }))
    },
    ImagesURL(){
      return this.Images.map(img=>`${this.statics}/${img.src}`);
    },
    headers(){
      return this.dataheader.map(item=>({
        ...HeaderDefault,
        ...item,
      }));
    },
    headersKhoHangKho(){
      return HeaderKhoKhoHangAdmin.splice(0,13);
    },
    headersDonHangKho(){
      const first = HeaderKhoDonHangAdmin.splice(0,14);
      const last = HeaderKhoDonHangAdmin.splice(-4);
      const headers = [
          ...first,
          ...last,
      ];
      console.log(headers)
      return headers;
    }
  },
  data(){
    return {
      getPDFState: null,
      FilterTime: [],
      menu_created_at: false,
      Options:{},
      allowDelete: true,
      Stats:{
        SumC1:0,
        SumC2: 0
      },
      Sales:[],
      KhachHangData:{
        TenNguoiMua:null,
        SDTNguoiMua:null,
        TenNguoiNhan:null,
        SDTNguoiNhan:null,
        DCNguoiNhan:null
      },
      boxKhachHang: false,
      updateCKState: false,
      updateCK:{
        CK:0,
        CKA:0,
        CKB: 0,
        ID: null,
      },
      boxCK: false,
      downloadTrackingExcel: false,
      Media:[],
      uploadStateHD: null,
      HDUpload: null,
      HDImage: null,
      downloadInvoiceExcel: null,
      downloadBarcodeState: null,
      createBatch:{
        SL:1,
        NH: null,
        CK: 0,
        CKA: 30,
        CKB: 20
      },
      totalItems:0,
      Filter:{
        NH:'all',
        Search:null,
        Mode: 'khoi-tao',
        TrangThai: null,
        ThanhToan: null,
        TTThanhToan: null,
        Sale: null,
        byDate: null,
      },
      selectedItems:[],
      downloadImageState: false,
      uploadState:false,
      selectedImg: [],
      notice: new Status(this.$swal),
      Search: null,
      boxAddBatch: false,
      boxAdd: false,
      boxNote: false,
      boxImage: false,
      createState: false,
      noteState: false,
      removeImageState: false,
      updateData:{
        ID:null,
        MaSP: null,
        ThuongHieu: null,
        ThongSo: null,
        TinhTrang: "100%",
        MoTa:null,
        NamSX:null,
        KL:null,
        Ton:1,
        GiaNhap:0,
        GiaNY: 0,
        CKA:0,
        CKB:0,
        CK:0,
        NH:null,
      },
      items:[],
      NH:[],
      loading: false,
      NoteID:null,
      Note:null,
      Notes:[],
      oNotes:[],
      Images:[],
      UploadImages:[],
      UploadImageState:[],
      UploadID: null,
      dataHeaders: [],
      downloadTrackingExcelReport: false,
    }
  },
  watch: {
    Options: {
      handler () {
        return this.doDownload();
      },
      deep: true,
    },
    $route:{
      handler () {
        return this.doDownload();
      },
      deep: true,
    }
  },
  methods:{
    truncate,
    parseDateTime,
    DoiTien,
    doExportReport(){
      this.downloadTrackingExcelReport = true;
      return Query.qrExportExcelReport({app: this}).finally(()=>{
        this.downloadTrackingExcelReport = false;
      });
    },
    doViewTracking(item){
      return this.$refs.checktracking.doOpen(item);
    },
    doGetPDF(item, is3x4 = false){
      this.getPDFState = item._id;
      return Query.qrPDF(item._id, is3x4).then(resp=>{
        const {error} = resp;
        if(error){
          this.notice.error(error);
        }else{
          const pdfUrl = URL.createObjectURL(resp);
          printJS(pdfUrl);
          return this.doDownload();
        }
      }).finally(()=>{
        this.getPDFState = null;
      })
    },
    doSaveFilterByDate(){
      this.Filter.byDate = this.FilterTime;
      return this.$refs.menu_created_at.save(this.FilterTime)
    },
    doCancelFilterByDate(){
      this.FilterTime = [];
      this.$refs.menu_created_at.save(this.FilterTime);
      return this.menu_created_at = false;
    },
    doToggleKhachHang(item){
      this.KhachHangData = {
        TenNguoiMua: item.TenNguoiMua,
        TenNguoiNhan: item.TenNguoiNhan,
        SDTNguoiMua: item.SDTNguoiMua,
        SDTNguoiNhan: item.SDTNguoiNhan,
        DCNguoiNhan: item.DCNguoiNhan,
      };
      this.boxKhachHang = true;
    },
    TinhLN(item, raw=false){
      if(item.LoiNhuanS1) return item.LoiNhuanS1;
      const CK = this.TinhPT(item);
      if(raw === true) return item.GiaNY*CK/100;
      return this.DoiTien(item.GiaNY*CK/100);
    },
    TinhPT(item){
      let CK = item.CK === 0 ? item.CKA:item.CKB;
      const myCK = item.CK === 0 ? this.loginInfo.CKA:this.loginInfo.CKB;
       return myCK > CK ? CK:myCK;
    },
    doUpdateCK(){
      this.updateCKState = true;
      const {CK, CKA, CKB, ID} = this.updateCK;
      return Query.qrUpdate(ID,{
        CK, CKA, CKB
      }).then(json=>{
        if(json.success){
          this.boxCK = false;
          this.notice.success("Đã cập nhật thông tin chiết khấu !!!");
          return this.doDownload();
        }
        if(json.error){
          this.notice.error(json.error);
        }
      }).catch(e=>{
        this.notice.error(e.message);
      }).finally(()=>this.updateCKState = false);
    },
    doToggleCK(item){
      this.updateCK = {
        CK: item.CK,
        CKA: item.CKA,
        CKB: item.CKB,
        ID: item._id
      };
      this.boxCK = true;
    },
    doUpdateTracking(items){
      return Query.qrUpdateTracking(items).then(()=>{
        this.notice.success("Đã cập nhật hoàn tất !!!");
        this.doDownload();
      }).catch(e=>{
        this.notice.error(e.message);
      }).finally(()=>this.$refs.nhaptracking.doClose());
    },
    doImportTracking(){
      return this.$refs.nhaptracking.doOpen();
    },
    doExportTrackingItems(){
      console.log(this.SelectedExportItemsTracking);
      if(this.SelectedExportItemsTracking.length > 0){
        this.downloadTrackingExcel = true;
        const items = this.SelectedExportItemsTracking.map(item=>item._id);
        return Query.qrExportExcelTracking({app: this, items}).finally(()=>{
          this.downloadTrackingExcel = false;
        });
      }
    },
    doConfirmPaymentItems(){
      if(this.SelectedConfirmPaymentItems.length > 0){
        const app = this;
        let items = [];
        this.SelectedConfirmPaymentItems.map(item => {
          if(!items.includes(item.HoaDon._id)){
            items.push(item.HoaDon._id);
          }
        });
        return this.notice.confirm("Xác nhận ?", "Hành động của bạn sẽ không thể phục hồi !!!").then(result=> {
          if(result) {
            return Query.qrConfirmPayment(items).finally(() => app.doDownload());
          }
        });
      }
    },
    doConfirmExportItems(){
      if(this.SelectedExportItems.length > 0){
        const app = this;
        const items = this.SelectedExportItems.map(item => item.id);
        return this.notice.confirm("Xác nhận ?", "Hành động của bạn sẽ không thể phục hồi !!!").then(result=> {
          if(result) {
            return Query.qrConfirmExport(items).finally(() => app.doDownload());
          }
        });
      }
    },
    doRestockItems(){
      if(this.SelectedDeleteItems.length > 0){
        const app = this;
        return this.notice.confirm("Xác nhận ?", "Hành động của bạn sẽ không thể phục hồi !!!").then(result=> {
          if(result) {
            const items = app.selectedItems.map(item => item.id);
            return Query.qrRestock(items).finally(() => app.doDownload());
          }
        });
      }
    },
    doDeleteItems(){
      if(this.SelectedDeleteItems.length > 0){
        const app = this;
        return this.notice.confirm("Xác nhận ?", "Hành động của bạn sẽ không thể phục hồi !!!").then(result=> {
          if(result) {
            const items = app.selectedItems.map(item => item.id);
            return Query.qrRemove(items).finally(() => app.doDownload());
          }
        });
      }
    },
    doViewImage(item){
      this.Media = [{
        type: 'image',
        src: `${this.staticsHD}/${item.HoaDon.Image}`,
        thumb: `${this.staticsHD}/${item.HoaDon.Image}`,
      }];
      this.$refs.lightbox2.showImage(0);
    },
    doExportInvoice(item){
      this.downloadInvoiceExcel = true;
      return QueryHD.qrExport({app:this,id:item.HoaDon._id, MaHD:item.MaHD}).finally(()=>{
        this.downloadInvoiceExcel = null;
      });
    },
    doExportBarCode(item){
      this.downloadBarcodeState = item._id;
      return Query.qrExportExcelBarcode({app:this,id:item._id, MaKho:item.MaKho}).finally(()=>{
        this.downloadBarcodeState = null;
      });
    },
    doNhapKhoExcel(){
      return this.$refs.nhapkho.doOpen();
    },
    doXuatExcel(){
      const items = this.selectedItems;
      const NH = this.Filter.NH;
      return Query.qrExportExcel({app:this, query:{items, NH}});
    },
    doXinXuatKho(item){
      let items =[];
      items.push(JSON.parse(JSON.stringify(item)));
      return this.$refs.xuatkho.doOpen(JSON.parse(JSON.stringify(items)));
    },
    doXuatKho(){
      if(this.countSelectedItemsInvalid > 0) return this.notice.error("Có đơn hàng không hợp lệ. Không thể xin xuất kho !");
      return this.$refs.xuatkho.doOpen(JSON.parse(JSON.stringify(this.selectedItems)));
    },
    doCreateBatch(){
      const {SL, NH, CK, CKA, CKB} = this.createBatch;
      if(isNaN(parseInt(SL))) return this.notice.error("Nhập số lượng !");
      if(!NH) return this.notice.error("Chọn ngành hàng !!!");
      this.createState = true;
      return Query.qrCreateBatch(this.createBatch).then(json=>{
        if(json.success){
          this.createBatch = {
            SL: 1,
            NH: null,
            CK: 0,
            CKA: 30,
            CKB: 20
          }
          this.boxAddBatch = false;
          return this.doDownload();
        }
        if(json.error){
          this.notice.error((json.error));
        }
      }).finally(()=>{
        this.createState =false;
      });
    },
    doClearSearch(){
      this.Filter.Search = null;
    },
    doSearch(){
      this.Filter.Search = this.Search;
    },
    doSearchNH(item){
      this.Options.page=1;
      this.Filter.NH = item===false ? 'all':item._id;
      return this.doDownload(true);
    },
    doDownloadALlImages(){
      this.downloadImageState = true;
        this.ImagesURL.map((url) => {
          const splitUrl = url.split("/");
          const filename = splitUrl[splitUrl.length - 1];
          fetch(url)
              .then((response) => {
                response.arrayBuffer().then(function (buffer) {
                  const url = window.URL.createObjectURL(new Blob([buffer]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", filename); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
              })
              .catch((err) => {
                console.log(err);
              });
        });
      this.downloadImageState=false;
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    },
    doConfirmRemoveImage(){
      return this.notice.confirm("Xác nhận xóa ?", "Hành động này không thể phục hồi !!!").then(result=>{
        if(result) return this.doRemoveImage();
      })
    },
    doRemoveImage(){
      this.removeImageState = true;
      return Query.qrRemoveImage(this.UploadID, this.selectedImg).then(()=>{
        return this.doCloseUpload();
      }).finally(()=>{
        this.removeImageState = false;
        return this.doDownload();
      });
    },
    doCloseUpload(){
      this.boxImage = false;
      this.uploadState = false;
      this.UploadImageState = [];
      this.UploadImages = [];
    },
    doStartUploadHD(item){
      this.HDUpload = item.HoaDon._id;
      this.HDImage = null;
      this.$refs.uploaderHD.$refs.input.click();
    },
    doStartUpload(){
      this.uploadState = false;
      this.UploadImageState = [];
      this.UploadImages = [];
      this.$refs.uploader.$refs.input.click();
    },
    doUploadFile(uri, file){
      return Query.qrUploadFile(uri, file).then(json=>{
        if(json.data){
          const {images, result} = json.data;
          if(images) this.Images = images.map(i=>({src:i}));
          if(result) this.UploadImageState = result;
        }
        if(json.error){
          this.notice.error(json.error);
        }
      }).catch(e=>{
        console.error(e);
        this.notice.error(e.message);
      });
    },
    doUploadFileHD(uri, file, id){
      this.uploadStateHD = id;
      return QueryHD.qrUploadFile(uri, file).then(json=>{
        if(json.success){
          this.notice.success("Đã tải lên hình ảnh cho hóa đơn !!!");
          return this.doDownload();
        }
        if(json.error){
          this.notice.error(json.error);
        }
      }).catch(e=>{
        console.error(e);
        this.notice.error(e.message);
      }).finally(()=>{
        this.uploadStateHD = null;
      });
    },
    doUploadHD() {
      if (this.HDImage) {
        this.doUploadFileHD(`/image/${this.HDUpload}`, this.HDImage, this.HDUpload);
      } else {
        this.notice.error('Vui lòng chọn hình ảnh để tải lên !!!');
      }
    },
    doUploadFiles(){
      if(this.UploadImages.length > 0){
        if((this.UploadImages.length+this.Images.length) === 5) {
          this.uploadState = true; this.UploadImageState = [];
          let Tasks = [];
          // for(let i=0;i<this.UploadImages.length;i++){
          //   const File = this.UploadImages[i];
          //   Tasks.push(this.doUploadFile(`/image/${this.UploadID}`, File));
          // }
          Tasks.push(this.doUploadFile(`/image/${this.UploadID}`, this.UploadImages));
          return Promise.all(Tasks).then(this.doDownload).finally(() => {
            this.uploadState = false;
          });
        }else{
          this.notice.error('Tổng số lượng hình ảnh phải là 5 hình');
          this.uploadState = false;
          this.UploadImageState = [];
          this.UploadImages = [];
        }
      }else{
        this.notice.error('Vui lòng chọn hình ảnh để tải lên !!!');
        this.uploadState = false;
        this.UploadImageState = [];
        this.UploadImages = [];
      }
    },
    doToggleImage(item){
      const Images = JSON.parse(JSON.stringify(item.Images));
      this.Images = Images.map(i=>({src:i}));
      this.selectedImg = [];
      this.UploadImages = [];
      this.uploadState = false;
      this.UploadImageState = [];
      this.UploadID = item._id;
      this.allowDelete = item.Ton === -1;
      this.boxImage = true;
    },
    doConfirmDeleteNote(index){
      const app = this;
      return this.notice.confirm("Xác nhận xóa ?", "Hành động này không thể phục hồi !!!").then(result=>{
        if(result) return app.doDeleteNote(index);
      });
    },
    doDeleteNote(index){
      this.oNotes = JSON.parse(JSON.stringify(this.Notes));
      this.Notes.splice(index,1);
      this.noteState = true;
      return Query.qrNotes(this.NoteID, this.Notes).then(json=>{
        if(json.success) {
          this.Note = null;
          this.notice.success("Đã cập nhật ghi chú !!!");
        }
      }).catch(e=>{
        this.notice.error(e.message);
        this.Notes = JSON.parse(JSON.stringify(this.oNotes));
      }).finally(()=>{
        this.noteState = false;
        return this.doDownload();
      });
    },
    doUpdateNote(){
      this.oNotes = JSON.parse(JSON.stringify(this.Notes));
      this.Notes.push(this.Note);
      this.noteState = true;
      return Query.qrNotes(this.NoteID, this.Notes).then(json=>{
        if(json.success) {
          this.Note = null;
          this.notice.success("Đã cập nhật ghi chú !!!");
        }
      }).catch(e=>{
        this.notice.error(e.message);
        this.Notes = JSON.parse(JSON.stringify(this.oNotes));
      }).finally(()=>{
        this.noteState = false;
        return this.doDownload();
      });
    },
    doToggleNote(item){
      this.NoteID = item._id;
      this.Notes = item.GhiChu ? item.GhiChu:[];
      this.boxNote  = true;
    },
    doDelete(item){
      const ID = item._id; const app = this;
      return this.notice.confirm("Xác nhận xóa ?", "Hành động này không thể phục hồi !!!").then(result=>{
        if(result){
          return Query.qrDelete(ID).then(()=>{
            app.notice.success("Đã xóa dữ liệu thành công !!!");
            return app.doDownload();
          })
        }
      });
    },
    doUpdate(){
      const {ID} = this.updateData;
      let Request = JSON.parse(JSON.stringify(this.updateData));
      delete Request.ID;
      if(!ID) return this.notice.error("Dữ liệu không hợp lệ !!!");
      this.createState = true; const notice = this.notice; const app = this;
      return Query.qrUpdate(ID, Request).then(json=>{
        if(json.success){
          notice.success("Đã cập nhật thông tin !!!");
          app.doCloseBoxAdd();
          return app.doDownload();
        }
        if(json.error){
          app.notice.error(json.error);
        }
      }).finally(()=>app.createState=false);
    },
    doToggleEdit(data){
      const {
        _id, ThuongHieu, MaSP, MoTa, NamSX, TinhTrang, KL, ThongSo, NH, GiaNhap, GiaNY,
        CKA,
        CKB,
        CK} = data;
      this.updateData = {
        ID:_id,
        ThuongHieu, MaSP, MoTa, NamSX, TinhTrang, KL, ThongSo, NH: NH._id,
        GiaNhap,
        GiaNY,
        CKA,
        CKB,
        CK
      };
      this.boxAdd = true;
    },
    doUpdateMa(){
      if(this.updateData.Ten){
        const Ma = initials(this.updateData.Ten);
        if(Ma) this.updateData.Ma = Ma;
      }
    },
    doCreate(){
      const {MaSP, ThuongHieu, ThongSo, TinhTrang, NamSX, KL, NH} = this.updateData;
      if(!MaSP || !ThuongHieu || !ThongSo || !TinhTrang || !NamSX || !KL || !NH) return this.notice.error("Điền đầy đủ thông tin !!!");
      this.createState = true; const notice = this.notice; const app = this;
      return Query.qrCreate(this.updateData).then(json=>{
        if(json.success){
          notice.success("Đã tạo mới ngành hàng !!!");
          app.doCloseBoxAdd();
          return app.doDownload();
        }
        if(json.error){
          app.notice.error(json.error);
        }
      }).finally(()=>app.createState=false);
    },
    doCloseBoxAdd(){
      this.boxAdd = false;
      this.updateData = {
        ID:null,
        MaSP: null,
        ThuongHieu: null,
        ThongSo: null,
        TinhTrang: "100%",
        MoTa:null,
        NamSX:null,
        KL:null,
        Ton:1,
        GiaNhap:0,
        GiaNY: 0,
        CKA:0,
        CKB:0,
        CK:0,
        NH:null,
      };
    },
    doDownload(reload = false){
      if(!reload) this.doChangeData();
      this.selectedItems = [];
      this.loading = true;
      const Filter = {
        ...this.Filter,
        ...this.Options,
      }
      return Query.qrList(Filter).then(json=>{
        if(json.data){
          const {items, Sale, Stats} = json.data;
          if(items) this.items = items;
          if(Sale) this.Sales = Sale;
          if(Stats) this.Stats = Stats;
        }
        if(json.total) this.totalItems = json.total;
      }).finally(()=>{
        this.loading=false
        this.Filter.Sale = null;
      });
    },
    doDownloadNH(){
      this.loading = true;
      return Query.qrListNH().then(json=>{
        if(json.data) this.NH = json.data;
      }).finally(()=>this.loading=false);
    },
    doDownloadData(){
      const tasks = [this.doDownload(),this.doDownloadNH()];
      return Promise.all(tasks);
    },
    doChangeData(){
      this.Filter.Mode = this.$route.params.Mode;
      this.Filter.NH = this.$route.params.NH;
      if(this.Filter.Mode === 'khoi-tao'){
        this.dataHeaders = (this.loginInfo._perm === 'admin') ? HeaderKhoNganhHangAdmin:HeaderKhoNganhHang;
      }
      if(this.Filter.Mode === 'ton-kho'){
        if(this.loginInfo._perm === 'warehousevn'){
          this.dataHeaders = this.headersKhoHangKho;
        }else {
          this.dataHeaders = (this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager') ? HeaderKhoKhoHangAdmin : HeaderKhoKhoHang;
        }
      }
      if(this.Filter.Mode === 'don-hang'){
        console.log(this.loginInfo._perm)
        if(this.loginInfo._perm === 'accounting'){
          this.dataHeaders = HeaderKhoDonHangKeToan;
        }
        if(this.loginInfo._perm === 'warehousevn'){
          this.dataHeaders = this.headersDonHangKho;
        } else {
          this.dataHeaders = (this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager') ? HeaderKhoDonHangAdmin : HeaderKhoDonHang;
        }
      }
      this.$forceUpdate();
    },
  },
  beforeMount() {
    this.doChangeData()
  },
  mounted() {
    this.doDownloadData();
  }
}
</script>

<style scoped>
.hide {
  visibility: hidden !important;
}
</style>
